import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { WINDOW_EXISTS } from '../../../helpers/viewport'

@inject('routerStore', 'scrollStore')
@observer
class ScrollToHash extends Component {

  options = {
    behavior: 'smooth'
  }


  constructor(props) {
    super(props);
    this.history = this.props.routerStore.history;
    this.shouldScroll = !this.props.onHistoryPush || this.history.action === 'PUSH';
  }

  async componentDidMount() {
    if(this.props.routerStore.location.hash && this.shouldScroll && WINDOW_EXISTS) {
      const el = document.getElementById(this.props.routerStore.location.hash.replace(/^#/, ''));
      if (el) {
        //el.scrollIntoView(this.options);
        setTimeout(
          () => {
            this.props.scrollStore.animateScrollTo({desiredOffset: el});
        }, 10);
      }
    }
  }

  async componentDidUpdate() {
    if(this.props.routerStore.location.hash && this.shouldScroll && WINDOW_EXISTS) {
      const el = document.getElementById(this.props.routerStore.location.hash.replace(/^#/, ''));
      if (el) {
        // el.scrollIntoView(this.options);
        setTimeout(
          () => {
            this.props.scrollStore.animateScrollTo({desiredOffset: el});
        }, 10);
      }
    }
  }

  render() {
    return <div className="d-none" data-hash={this.props.routerStore.location.hash}></div>;
  }
}
export default ScrollToHash;