import React, { Component } from 'react';
import { Switch, matchPath } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { ROUTES as routes, RouteWithSubRoutes } from './routes/routes';

@inject('navigationStore')
@observer
class App extends Component {
  render() {
    const location = this.props.location;
    let match = null;
    for (let i = 0; i < routes.length; i++) {
      match = matchPath(location.pathname, routes[i]);
      if (match) {
        break;
      }
    }

    return (
      <div className="wrap">
        <Switch key={match.path}>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
    );
  }
}

export default App;
