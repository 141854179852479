import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('navigationStore')
@observer
export default class LangSwitch extends Component {

  render() {
    const data = this.props.navigationStore.langSwitchData;
    return (
      <div className="mink-main-nav__lang">
        {Object.keys(data).map((lang) => {
          return <a key={lang} className={'-link mink-main-nav__lang-' + lang} href={data[lang].path}>{data[lang].tag}</a>;
        })}
      </div>
    );
  }
}
