import React, { Component } from 'react';
import Flickity from 'react-flickity-component';
import animateScrollTo from 'animated-scroll-to';
import Masonry from 'react-masonry-css';

class SingleContent extends Component {
  /**
   * holds the dom ref of the sider
   */
  slider = {};
  navRef = React.createRef();
  navImages = [];

  breakpointCols = {
    default: 3,
    1199: 2,
    767: 1,
  };

  // see https://flickity.metafizzy.co/api.html
  options = {
    freeScroll: false,
    freeScrollFriction: 0.04, // default 0.075
    selectedAttraction: 0.015, // default 0.025
    friction: 0.2, // default 0.28
    contain: true,
    adaptiveHeight: true,
    pageDots: false,
    // setGallerySize: false,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 65,
      y2: 45,
      x3: 20,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  onCLickNavItem = (index) => (e) => {
    console.log(index);
    if (this.slider.isActive) {
      this.slider.select(index, false, true);
    }
    if (this.navRef.current && this.navImages[index].current) {
      console.log(this.navRef.current, this.navImages[index].current);
      animateScrollTo(this.navImages[index].current, {
        elementToScroll: this.navRef.current,
        minDuration: 0,
        maxDuration: 0,
      });
    }
    if (window.screen.width > 720) {
      this.setState({
        modal: true,
      });
    }
  };

  toggle = () => {
    this.setState({
      modal: false,
    });
  };

  onKeyPressed = (e) => {
    if ('Escape' === e.key) {
      this.toggle();
    }
  };

  render() {
    const images = this.props.images;

    const slider = images.map((item) => {
      return (
        <div className="mink-slider__item d-flex" key={'slider-' + item.ID}>
          <div className="-wrapper m-auto text-center">
            <img
              src={item.sizes.large}
              className="align-self-center"
              alt={item.alt}
              title={item.title}
              srcSet={item.sizes.large + ' 1600w,' + item.sizes.medium + ' 800w'}
              sizes="(max-width: 1600px) 100vw, 1600px"
            />
            <p>{item.caption}</p>
          </div>
        </div>
      );
    });

    const sliderNavi = images.map((item, i) => {
      this.navImages[i] = React.createRef();
      return (
        <div
          className="mink-masonry__item"
          key={'navi-' + item.ID}
          onClick={this.onCLickNavItem(i)}
          ref={this.navImages[i]}
        >
          <img src={item.sizes.large} className="align-self-center" alt={item.alt} title={item.title} />
          <div className='d-md-none mb-5'>{item.caption}</div>
        </div>
      );
    });

    return (
      <div className="mink-single" tabIndex={-1} onKeyDown={this.onKeyPressed}>
        <div ref={this.navRef}>
          <Masonry
            breakpointCols={this.breakpointCols}
            className={'mink-masonry'}
            columnClassName="mink-masonry__column"
          >
            {sliderNavi}
          </Masonry>
        </div>

        <div className={this.state['modal'] ? 'mink-modal -active' : 'mink-modal'}>
          <div className="mink-modal__content">
            <Flickity flickityRef={(c) => (this.slider = c)} className="mink-slider" options={this.options}>
              {slider}
            </Flickity>
          </div>
          <div className="mink-modal__close" onClick={() => this.toggle()}></div>
        </div>
      </div>
    );
  }
}

export default SingleContent;
