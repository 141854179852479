import React, { Component } from 'react';

class LoadingAnimation extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 d-flex align-items-center justify-content-center mink-la-wrapper">
          <div className="mink-la__dual-ring"></div>
          <div>
            {/* <div className="mink-la"><div></div><div></div><div></div></div> */}
            {/* <div className="loadingio-spinner-square-6zyafu1il3">
              <div className="ldio-sqcs102tfx8">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div> */}
          </div>          
        </div>
      </div>
    );
  }
}

export default LoadingAnimation;