import { WINDOW_EXISTS } from '../../helpers/viewport';
import { action, observable } from 'mobx';

export default class WindowStore {

  delay = 250;
  /**
   * holder for timeout id
   */
  timeout = false;
  @observable pageSize = 'xs';
  @observable windowHeight = 0;
  // windowRatio <= 1 landscape; windowRatio > 1 portrait 
  @observable windowRatio = 0.75;
  
  /**
   * array with window sizes
   * @see https://getbootstrap.com/docs/4.0/layout/grid/ 
   */
  sizes = [
    {name: 'xs',  width: 0},
    {name: 'sm',  width: 576},
    {name: 'md',  width: 768},
    {name: 'lg',  width: 992},
    {name: 'xl',  width: 1360},
    {name: 'xxl', width: 1860},
  ];

  imageSizes = [
    {name: 'xs', suffix: 'medium'},
    {name: 'md', suffix: 'large'},
    {name: 'lg', suffix: ''},
  ];

  currentImageSizeIndex = 0;

  sizesOrder = this.sizes.map((size) => size.name);
  imageSizesOrder = this.imageSizes.map((size) => size.name);

  constructor() {
    this.handleResize = this.handleResize.bind(this);
    if (WINDOW_EXISTS) {
      window.addEventListener('resize', this.handleResize);
      this.setPageSize();
      this.setWindowHeight();
      this.setWindowRatio();
    }
  }

  handleResize() {
    // clear the timeout
    clearTimeout(this.timeout);
    // start timing for event "completion"
    this.timeout = setTimeout(
      () => {
        this.setPageSize();
        this.setWindowHeight();
        this.setWindowRatio();
      },
      this.delay
    );
  }

  get innerWidth() {
    if (WINDOW_EXISTS) {
      return window.innerWidth;
    } else {
      return 1;
    }
  }

  get innerHeight() {
    if (WINDOW_EXISTS) {
      return window.innerHeight;
    } else {
      return 1;
    }
  }

  get scrollPosition() {
    if (WINDOW_EXISTS) {
      return window.pageYOffset
    } else {
      return 0;
    }
  }


  @action
  setPageSize = () => {
    const w = window.innerWidth;
    let currentSize = this.sizes[0].name;
    for (let i = 1; i < this.sizes.length; i++) {
      if (this.sizes[i].width >= w ) {
        break;
      }
      currentSize = this.sizes[i].name;
    }
    this.pageSize = currentSize;
  }

  @action
  setWindowHeight = () => {
    this.windowHeight = this.innerHeight;
  }

  @action
  setWindowRatio = () => {
    this.windowRatio = parseFloat((this.innerHeight / this.innerWidth).toPrecision(2));
  }

  isGreaterThan(size) {
    return this.sizesOrder.indexOf(this.pageSize) > this.sizesOrder.indexOf(size);
  }

  isSmallerThan(size) {
    return this.sizesOrder.indexOf(this.pageSize) < this.sizesOrder.indexOf(size);
  }

  isGreaterEqualThan(size) {
    return this.sizesOrder.indexOf(this.pageSize) >= this.sizesOrder.indexOf(size);
  }

  isSmallerEqualThan(size) {
    return this.sizesOrder.indexOf(this.pageSize) <= this.sizesOrder.indexOf(size);
  }

  @action
  setWindowHeight = () => {
    this.windowHeight = this.innerHeight;
  }


  /**
   * get the header images size suffix for viewport width
   * use as ['thumbnail' +  suffix]
   */
  getImageSizeSuffix() {
    // filter suffixes viewport is greater or equal
    const sizes = this.imageSizes.filter((size) => this.isGreaterEqualThan(size.name));

    // get last fitting element
    const size  = sizes.slice(-1)[0];
    const index = this.imageSizesOrder.indexOf(size.name);

    // change only if its a larger size
    if (index > this.currentImageSizeIndex) {
      this.currentImageSizeIndex = index;
    }
    return this.imageSizes[this.currentImageSizeIndex];
  }
}
