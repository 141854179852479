import React, { Component } from 'react';
import { renderText } from '../../../helpers/api';


class SubMenu extends Component {

  render () {
    const items = this.props.items;
    const level = this.props.depthLevel;
    const currentClass = 'current-menu-item';
    const handleClick = item => e => {
      const extern = item.classes.indexOf('nav-extern') !== -1;
      !extern && this.props.handleClick(e, item);
    };

    return (
      <ul className={!level ? 'main-menu list-unstyled' : 'sub-menu list-unstyled'}>
      {
        items.map((item, i) => {
          let current = this.props.isCurrentMenuItem(item.url_components);
          let currentChild = false;
          
          item.children &&  item.children.map ((child) => {            
            currentChild = this.props.isCurrentMenuItem(child.url_components);
            if (currentChild) {
              current = true;
            } 
            return false;
          });

          return (
            <li key={'menu_item_' + item.menu_item_id} className={'nav_item ' + (current ? currentClass : '') + ' ' + (item.classes.join(' '))}>
              <a href={item.url} onClick={handleClick(item)} target={item.target}>{renderText(item.title)}</a>
              {
                item.children && <SubMenu items={item.children} depthLevel={level + 1} handleClick={this.props.handleClick} isCurrentMenuItem={this.props.isCurrentMenuItem} currentPath={this.props.currentPath}/>
              }
            </li>
          );
        })
      }
      </ul>
    );
  }
}

export default SubMenu
