import React, { Component } from 'react';
import Flickity from 'react-flickity-component';
import animateScrollTo from 'animated-scroll-to';

class SingleArchive extends Component {
  /**
   * holds the dom ref of the sider
   */
  slider = {};
  navRef = React.createRef();
  navImages = [];
  imageNum = -1;

  // see https://flickity.metafizzy.co/api.html
  options = {
    freeScroll: false,
    freeScrollFriction: 0.04, // default 0.075
    selectedAttraction: 0.015, // default 0.025
    friction: 0.2, // default 0.28
    contain: true,
    adaptiveHeight: true,
    pageDots: false,
    // setGallerySize: false,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 65,
      y2: 45,
      x3: 20,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  onCLickNavItem = (index) => (e) => {
    if (this.slider.isActive) {
      this.slider.select(index, false, true);
    }
    if (this.navRef.current && this.navImages[index].current) {
      animateScrollTo(this.navImages[index].current, {
        elementToScroll: this.navRef.current,
        minDuration: 0,
        maxDuration: 0,
      });
    }
    if (window.screen.width > 720) {
      this.setState({
        modal: true,
      });
    }
  };

  toggle = () => {
    this.setState({
      modal: false,
    });
  };

  onKeyPressed = (e) => {
    if ('Escape' === e.key) {
      this.toggle();
    }
  };

  getImages(year) {
    return year.map((image, i) => {
      this.imageNum++;
      this.navImages[this.imageNum] = React.createRef();
      return (
        <div
          className={'mink-gallery__image navi-' + image.archive_image.ID}
          key={'navi-' + image.archive_image.ID}
          onClick={this.onCLickNavItem(this.imageNum)}
          ref={this.navImages[this.imageNum]}
        >
          <div className="image">
            <img
              src={image.archive_image.sizes.large}
              className="align-self-center"
              alt={image.archive_image.alt}
              title={image.archive_image.title}
              srcSet={image.archive_image.sizes.large + ' 1600w,' + image.archive_image.sizes.medium + ' 800w'}
              sizes="(max-width: 1600px) 100vw, 1600px"
            />
          </div>
          <div className="caption">
            {image.archive_image.caption}
            {/* <br />
            <div className="avail">{image.image_avail && image.image_avail}</div> */}
          </div>
        </div>
      );
    });
  }

  render() {
    const years = this.props.years;
    let canArchive = false;
    if (typeof window !== 'undefined' && typeof window.MINK !== 'undefined' && window.MINK.can_archive) {
      canArchive = window.MINK.can_archive;
    }
    if ('0' === canArchive) {
      canArchive = false;
    }
    // we remove login
    canArchive = 1;

    const slider = years.map((slideritem) => {
      return slideritem.archive_images.map((sliderimage, i) => {
        return (
          <div
            className={'mink-slider__item d-flex slider-' + sliderimage.archive_image.ID}
            key={'slider-' + sliderimage.archive_image.ID}
          >
            <div className="-wrapper m-auto text-center">
              <img
                src={sliderimage.archive_image.sizes.large}
                className="align-self-center"
                alt={sliderimage.archive_image.alt}
                title={sliderimage.archive_image.title}
                srcSet={
                  sliderimage.archive_image.sizes.large + ' 1600w,' + sliderimage.archive_image.sizes.medium + ' 800w'
                }
                sizes="(max-width: 1600px) 100vw, 1600px"
              />
              <p>{sliderimage.archive_image.caption}</p>
            </div>
          </div>
        );
      });
    });

    const showYears = years.map((item, i) => {
      let yearsImages = this.getImages(item.archive_images);
      return (
        <div className="mink-gallery" key={item.archive_year + i}>
          <h2 className="mink-gallery__headline">{item.archive_year}</h2>
          <div className="mink-gallery__items">{yearsImages}</div>
        </div>
      );
    });

    this.imageNum = -1;

    if (canArchive) {
      return (
        <div className="mink-single" tabIndex={-1} onKeyDown={this.onKeyPressed}>
          <div ref={this.navRef}>{showYears}</div>

          <div className={this.state['modal'] ? 'mink-modal -active' : 'mink-modal'}>
            <div className="mink-modal__content">
              <Flickity flickityRef={(c) => (this.slider = c)} className="mink-slider" options={this.options}>
                {slider}
              </Flickity>
            </div>
            <div className="mink-modal__close" onClick={() => this.toggle()}></div>
          </div>
        </div>
      );
    } else {
      window.location.reload();
    }
    return false;
  }
}

export default SingleArchive;
