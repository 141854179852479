import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import { getDefaultLang } from '../../helpers/lang';
import { getRandomInt } from '../../helpers/math';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import HomePageTeaser from './HomePageTeaser';

@inject('pagesStore', 'navigationStore')
@observer
class HomePage extends Component {
  slug = 'homepage';

  constructor(props) {
    super(props);
    this.getCurrentLang();
    this.showQuote = getRandomInt(7) + 1;
  }

  renderHead() {
    if (
      this.props.pagesStore.isFetched.get(this.slug) &&
      this.props.pagesStore.data.posts[this.slug].location &&
      this.props.pagesStore.data.posts[this.slug].location.title
    ) {
      return renderTitle(this.props.pagesStore.data.posts[this.slug].location.title, false);
    } else {
      return null;
    }
  }

  getCurrentLang = () => {
    if (this.props.match && this.props.match.params) {
      const lang = this.props.match.params.lang;
      this.lang = lang ? lang : getDefaultLang();
    }
    if ('de' === this.lang) {
      this.slug = 'homepage';
    }
  };

  componentDidMount() {
    this.props.pagesStore.fetchIfNeeded(this.slug, this.lang);
    document.body.classList.add('page', '-homepage');
    if (this.props.pagesStore.isFetched.get(this.slug)) {
      this.props.navigationStore.setActivePostLangs({});
    }
  }
  componentWillUnmount() {
    document.body.classList.remove('page', '-homepage');
  }

  componentDidUpdate() {
    this.getCurrentLang();
    this.props.pagesStore.fetchIfNeeded(this.slug, this.lang);
    if (this.props.pagesStore.isFetched.get(this.slug)) {
      this.props.navigationStore.setActivePostLangs({});
    }
  }

  render() {
    const isFetched = this.props.pagesStore.isFetched.get(this.slug);
    const post = this.props.pagesStore.data.posts[this.slug];
    const scrollToTop =
      !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);
    if (isFetched && false === post) {
      return <NoMatch location={this.props.location} />;
    }

    return (
      <div className="container-fluid">
        <Header />
        {isFetched ? (
          <main className={'mink-main'}>
            <>
              {post.fields && post.fields.field_hp_img && (
                <img
                  className="homepage-background"
                  src={post.fields.field_hp_img.sizes.large}
                  alt={post.fields.field_hp_img.alt}
                />
              )}
            </>
            <div className="mink-page">
              <div className="mink-content">
                {scrollToTop && <ScrollToTop onHistoryPush={true} />}
                <ScrollToHash />
                {this.renderHead()}
                <div className="row">
                  <div className="col-12 -post-content">{renderText(post.content)}</div>
                </div>
                <div className={'hp-teaser'}>
                  <div className={'hp-teaser__content'}>
                    {post.fields && post.fields.field_hp_teasers && (
                      <HomePageTeaser teasers={post.fields.field_hp_teasers} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        ) : (
          <LoadingAnimation />
        )}
        <Footer />
      </div>
    );
  }
}

export default HomePage;
