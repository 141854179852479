import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Nav from '../../containers/Nav/Nav';
@inject('windowStore')
@observer
class Header extends Component {
  render() {
    return (
      <header className={'mink-header'}>
        <Nav />
      </header>
    );
  }
}
export default Header;
