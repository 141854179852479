// Check if code is running in the browser (important for universal rendering)
const WINDOW_EXISTS = typeof window !== 'undefined';

/**
 * Check if element hits vertical center of viewport
 * @param {HTMLElement} element HTMLElement
 * @param {Event} e Event
 * 
 * @returns bool
 */
const isInCenterOfViewPort = (element, e) => {
  // Check if element hits  vertical center of viewport
  // Small offset is added to prevent page jumping
  if (!WINDOW_EXISTS || !(element instanceof HTMLElement)) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  const isTopAboveCenter = rect.top < window.innerHeight/2;
  const isBottomBelowCenter = rect.bottom > window.innerHeight/2;

  if (!isTopAboveCenter || !isBottomBelowCenter) {
    return false;
  } else {
    return true;
  }
};

export {
  WINDOW_EXISTS,
  isInCenterOfViewPort
};