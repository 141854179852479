import React, { Component } from 'react';
import { renderText } from '../../helpers/api';

class HomePageTeaser extends Component {
  render() {
    const teasers = this.props.teasers;
    const teaserList = teasers.map((teaser, i) => {
      return (
        <div className={'hp-teasers'} key={i}>
          <div className="hp-teasers__hd">{renderText(teaser.hp_teaser_hd)}</div>
          <div className="hp-teasers__txt">{renderText(teaser.hp_teaser_txt)}</div>
        </div>
      );
    });
    return <>{teaserList}</>;
  }
}

export default HomePageTeaser;
