import React, { Component } from 'react';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import LangSwitch from '../../components/nav/LangSwitch/LangSwitch';

@inject('navigationStore', 'routerStore')
@observer
class Nav extends Component {
  menuItems = [];

  constructor(props) {
    super(props);
    this.menuItems = props.navigationStore.primaryNavigationItems;
  }

  render() {
    const currentPath = this.props.routerStore.location.pathname;
    const isCurrentMenuItem = this.props.navigationStore.isCurrentMenuItem;
    const handleClick = this.props.navigationStore.handleClick;
    const toggleNav = this.props.navigationStore.toggleNav;

    return (
      <div>
        <nav className="mink-main-nav">
          <div className="mr-auto">
            <div className="mink-header__brand">
              <Link to="/">Isabelle Dutoit</Link>
            </div>
          </div>
          <div className="">
            <div className={'mink-main-nav__container'}>
              <div className="mink-main-nav__menu">
                <SubMenu
                  items={this.menuItems}
                  depthLevel={0}
                  isCurrentMenuItem={isCurrentMenuItem}
                  handleClick={handleClick}
                  currentPath={currentPath}
                />
              </div>
            </div>
          </div>
          <LangSwitch />
        </nav>
        <nav className="mink-main-mobile-nav">
          <div className="mink-main-mobile-nav__header">
            <div className="mink-header__brand">
              <Link to="/">Isabelle Dutoit</Link>
            </div>
            <div className="mink-main-mobile-nav__toggler" onClick={toggleNav}>
              <span
                className={'navbar-toggler-icon' + (this.props.navigationStore.navIsActive ? ' is_active' : '')}
              ></span>
            </div>
          </div>
          <div
            className={
              'mink-main-mobile-nav__container' + (this.props.navigationStore.navIsActive ? ' -show' : ' -hide')
            }
          >
            <div className="mink-main-mobile-nav__toggler" onClick={toggleNav}>
              <span
                className={'navbar-toggler-icon' + (this.props.navigationStore.navIsActive ? ' is_active' : '')}
              ></span>
            </div>
            <div className="mink-main-mobile-nav__menu">
              <SubMenu
                items={this.menuItems}
                depthLevel={0}
                isCurrentMenuItem={isCurrentMenuItem}
                handleClick={handleClick}
                currentPath={currentPath}
              />
            </div>
            <LangSwitch />
          </div>
        </nav>
      </div>
    );
  }
}

export default Nav;
