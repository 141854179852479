import { action, observable } from 'mobx';

export default class SidebarsStore {

  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api
  data = {
    posts: {},
    ids: [],
    meta: {},
  };
  @observable isFetched = new Map();
  isFetching = [];
  slug = 'sidebar-footer';
  restBase = 'mink';

  constructor( api ) {
    this.api = api;
  }


  @action
  fetch() {
    this.isFetching[this.slug] = true;
    this.api.sidebars = this.api.registerRoute(this.restBase + '/v1', '/sidebars/(?P<id>[\\w-]+)', { params: ['param']});    
    this.api.sidebars().id(this.slug)
      .then((post) => {
        
        if ( post && post.hasOwnProperty(this.slug) ) {
          this.data.posts[this.slug] = post[this.slug];
        } else {
          this.data.posts[this.slug] = false;
        }
        this.isFetched.set(this.slug, true);
        this.isFetching[this.slug] = false;
      })
      .catch(error => console.error(error));

    ;
  }

  fetchIfNeeded(slug) {
    this.slug = '' !== slug ? slug : 'sidebar-footer';
    if ( !this.isFetching[this.slug] && !this.isFetched.get(this.slug)) {
      this.fetch();
    }
  }
}

