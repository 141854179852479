import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
// import { renderText } from '../../../helpers/api';
import Flickity from 'react-flickity-component';

/**
 * A scroller, used as Block for Gutenberg Editor, to display items in flickity slider
 * @see https://flickity.metafizzy.co/options.html
 */
class ContentSlider extends Component {

  slider = {};

  options = {
    freeScroll: true,
    freeScrollFriction: 0.04,
    contain:true,
    // adaptiveHeight: true,
    pageDots: false,
    arrowShape: { 
      x0: 10,
      x1: 60, y1: 50,
      x2: 65, y2: 45,
      x3: 20
    }
  };

  render() {
    const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
    return (
      <VisibilitySensor partialVisibility={true} delayedCall={true} offset={{top:100, bottom: 200}}>
        <div id={this.props.attribs.id}>
          <Flickity className={this.props.attribs.className} options={this.options}>
            {children}
          </Flickity>
        </div>
      </VisibilitySensor>
    );
  }
}

export default ContentSlider;