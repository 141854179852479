import { getQueryStringParams } from './api';

const getCurrentLang = () => {
  let lang = 'de';
  if ( typeof window !== 'undefined' && typeof window.location !== 'undefined' && window.location.search ) {
    const queryParams = getQueryStringParams(window.location.search);
    if ( queryParams.lang ) {
      return queryParams.lang;
    }
  }
  
  if ( typeof window !== 'undefined' && typeof window.MINK !== 'undefined' && window.MINK.cl ) {
    lang = window.MINK.cl;
  }
  return lang;
}

const getDefaultLang = () => {
  let lang = 'de';
  if ( typeof window !== 'undefined' && typeof window.MINK !== 'undefined' && window.MINK.dl ) {
    lang = window.MINK.dl;
  }
  return lang;
}

const getLangPrefix = () => {
  return getDefaultLang() === getCurrentLang() ? '' : '/' + getCurrentLang();
}

export {
  getCurrentLang,
  getDefaultLang,
  getLangPrefix,
}