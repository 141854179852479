import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import { getDefaultLang } from '../../helpers/lang';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SingleContent from './SingleContent';
import SingleArchive from './SingleArchive';

@inject('pagesStore', 'navigationStore')
@observer
class Single extends Component {
  slug = '';
  store = {};

  constructor(props) {
    super(props);
    this.getCurrentSlug();
    this.getCurrentLang();
    this.store = this.props.store ? this.props.store : this.props.pagesStore;
    this.homepage = {};
  }

  renderHead() {
    if (this.store.isFetched.get(this.slug) && this.store.data.posts[this.slug].title) {
      return renderTitle(this.store.data.posts[this.slug].title);
    } else {
      return null;
    }
  }

  getCurrentSlug = () => {
    if (this.props.match && this.props.match.params) {
      if (this.props.apiParams && this.props.apiParams.postType) {
        this.slug = this.props.location.pathname;
      } else {
        const slug = this.props.match.params.id;
        this.slug = slug ? slug : 'homepage';
      }
    }
  };
  getCurrentLang = () => {
    if (this.props.match && this.props.match.params) {
      const lang = this.props.match.params.lang;
      this.lang = lang ? lang : getDefaultLang();
    }
  };

  componentDidMount() {
    if (this.props.apiParams && this.props.apiParams.postType) {
      this.store.fetchPathIfNeeded(this.slug);
    } else {
      this.store.fetchIfNeeded(this.slug, this.lang);
    }

    document.body.classList.add('page');
    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.add('single-' + postType);
      this.props.navigationStore.setActivePostLangs(this.store.data.posts[this.slug].translations);
    }
  }
  componentWillUnmount() {
    document.body.classList.remove('page');
    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.remove(postType, 'single-' + postType);
    }
  }

  componentDidUpdate() {
    this.getCurrentSlug();
    this.getCurrentLang();
    if (this.props.apiParams && this.props.apiParams.postType) {
      this.store.fetchPathIfNeeded(this.slug);
    } else {
      this.store.fetchIfNeeded(this.slug, this.lang);
    }

    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.add('single-' + postType);
      this.props.navigationStore.setActivePostLangs(this.store.data.posts[this.slug].translations);
    }
  }

  render() {
    const isFetched = this.store.isFetched.get(this.slug);
    const post = this.store.data.posts[this.slug];
    const scrollToTop =
      !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);

    if ((isFetched && false === post) || (this.store.isFetchedAll && !post)) {
      return <NoMatch />;
    }

    return (
      <div className="container-fluid">
        <Header />
        {isFetched ? (
          <main className="mink-main">
            <div className="mink-page">
              <div className="mink-content">
                {scrollToTop && <ScrollToTop onHistoryPush={true} />}
                <ScrollToHash />
                {this.renderHead()}
                <div className="row">
                  <div className="col-12 -post-content">{renderText(post.content)}</div>
                </div>
                {post.fields && post.fields.isadu_archive ? (
                  <SingleArchive years={post.fields.isadu_archive} />
                ) : (
                  <div></div>
                )}
                {post.fields && post.fields.field_isadu_work ? (
                  <SingleContent content={post.content} images={post.fields.field_isadu_work} />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </main>
        ) : (
          <LoadingAnimation />
        )}
        <Footer />
      </div>
    );
  }
}

export default Single;
